import React, { useEffect } from 'react';
import {
    Card,
    CardBody,
    Button,
    CardHeader,
    CardFooter,
    Link,
} from '@spglobal/react-components';
import styled from '@emotion/styled';
import { Classes } from '@spglobal/koi-helpers';
import { TextBodyLg, TextBodyMd } from '../shared';
import IconBand from '../IconBand/IconBand';
import { useHistory } from 'react-router-dom';

const ProductItem = styled.div`
    display: flex;
    border: 1px solid var(--color-base-gray-11);
    border-radius: var(--size-space-sm);
    overflow: hidden;
    padding: 5px;
    vertical-align: middle;
`;

const ProductItemCard = styled(Card)`
    display: inline-block;
    overflow: hidden;
    width: 100%;
    vertical-align: middle;
    height: 100px;
`;

const NoSubscriptionText = styled(TextBodyMd)`
    font-style: italic;
`;

export default function ProductCard({ product }) {
    const history = useHistory();

    if (!product) {
        return <div data-testid="no-product"></div>;
    }
    
    const {
        productName,
        productDescription,
        path,
        isSubscribed,
        details,
        isNew,
        isNewText,
        contact,
        isGreyed,
    } = product;

    useEffect(() => {
        const linkElementClimateRisk = document.getElementById('productClimateRiskContactLinkId');
        const linkElementNatureRisk = document.getElementById('productNatureRiskContactLinkId');

        if (productName === 'Climate Risk' && linkElementClimateRisk)
            linkElementClimateRisk.href = contact;

        if (productName === 'Nature Risk' && linkElementNatureRisk)
            linkElementNatureRisk.href = contact;
    }, []);

    const handleLaunch = (e) => {
        e.preventDefault();
        if (path === '/real-assets') {
            // Store the intended destination in localStorage
            localStorage.setItem('postLoginRedirect', path);
        }
        // Navigate to the path
        history.push(path);
    };

    return (
        <ProductItem>
            <IconBand iconType={productName} />
            <ProductItemCard hasRoundedCorner>
                <div className="spg-d-flex spg-justify-between spg-h-500">
                    <div className="spg-d-flex spg-flex-column spg-justify-center">
                        <div className="spg-d-flex">
                            <CardHeader data-testid="product-title" title={productName} />
                            {isNew && (
                                <span className={`${Classes.LABEL_REQUIRED} spg-ml-xs`}>
                                    {isNewText}
                                </span>
                            )}
                        </div>
                        <div className="spg-d-flex spg-justify-between">
                            <CardBody>
                                <TextBodyLg data-testid="product-description">
                                    {productDescription}
                                </TextBodyLg>
                            </CardBody>
                        </div>
                        <div>
                            {!isSubscribed && (
                                <CardFooter className="spg-text-right spg-align-top">
                                    <NoSubscriptionText>
                                        This product is available based on subscription. Please{' '}
                                        {productName == 'Climate Risk' && (
                                            <Link id="productClimateRiskContactLinkId" href="">
                                                contact us
                                            </Link>
                                        )}
                                        {productName == 'Nature Risk' && (
                                            <Link id="productNatureRiskContactLinkId" href="">
                                                contact us
                                            </Link>
                                        )}{' '}
                                        for trial access.
                                    </NoSubscriptionText>
                                </CardFooter>
                            )}
                        </div>
                    </div>
                    <div className="spg-d-flex spg-flex-column spg-justify-center">
                        {isSubscribed && (
                            <CardFooter className="spg-text-right spg-align-center">
                                <Button 
                                    purpose="primary" 
                                    className="btn-min-width"
                                    onClick={handleLaunch}
                                >
                                    {isSubscribed ? 'Launch' : 'Request Trial'}
                                    <span className="sr-only">{productName}</span>
                                </Button>
                            </CardFooter>
                        )}
                    </div>
                </div>
            </ProductItemCard>
        </ProductItem>
    );
}

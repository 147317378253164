import axios from 'axios';

import config from '../config';

const oktaStorage = JSON.parse(localStorage.getItem('okta-token-storage'));

const apiClient = axios.create({
    baseURL: `${config.baseUrl}/api`,
    headers: { Authorization: oktaStorage?.idToken?.idToken || '',
               Authentication: oktaStorage?.accessToken?.accessToken ||''
     },
});

export default apiClient;

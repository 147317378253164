const parseImpactPathways = (impactPathways) => {
    if (impactPathways[0]?.xValues) {
        return impactPathways.map((impactPathway) => ({
            ...impactPathway,
            xValues: impactPathway.xValues.split(';'),
            yValues: impactPathway.yValues.split(';'),
        }));
    }
    return [
        {
            name: '',
            xValues: ['100', '90', '90', '80', '80', '70'],
            yValues: ['', '', '', '', '', ''],
        },
    ];
};

// TODO: if we aren't reusing the Impact Function Form, align form values with this object
const transformImpactFunction = (rawData,copiedImpactFunctionId=0) => {
    const formattedIF = {
        name: rawData.impactFunctionName,
        hazard_id: Array.isArray(rawData.hazard) ? rawData.hazard[0].id : rawData.hazard,
        risk_factor_id: Array.isArray(rawData.riskFactor) ? rawData.riskFactor[0].id : rawData.riskFactor,
        description: rawData.impactFunctionDescription,
        x_metric: rawData.xMetric,
        x_metric_unit: rawData.xMetricUnits,
        x_label: rawData.xAxisLabel ? rawData.xAxisLabel : rawData.xMetric,
        y_metric: rawData.yMetric,
        y_metric_unit: rawData.yMetricUnits,
        y_label: rawData.yAxisLabel ? rawData.yAxisLabel : rawData.yMetricUnits,
        impact_pathway: rawData.impactPathways,
        copied_impact_function_id:copiedImpactFunctionId,
    };

    return formattedIF;
};

export default {
    transformImpactFunction,
    parseImpactPathways,
};

import React, { useEffect } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { BrowserRouter } from 'react-router-dom';
import * as constants from '../utils/constants';

const OktaProvider = ({ children }) => {
    const oktaConfig = {
        ...constants.OKTA_CONFIG,
        tokenManager: {
            storage: {
                getItem: (key) => {
                    // Store refresh token in localStorage
                    if (key.includes('refresh')) {
                        return localStorage.getItem(key);
                    }
                    // Everything else in sessionStorage
                    return localStorage.getItem(key);
                },
                setItem: (key, value) => {
                    if (key.includes('refresh')) {
                        localStorage.setItem(key, value);
                    } else {
                        localStorage.setItem(key, value);
                    }
                },
                removeItem: (key) => {
                    localStorage.removeItem(key);
                    localStorage.removeItem(key);
                }
            }
        }
    };

    const oktaAuth = new OktaAuth(oktaConfig);

    useEffect(() => {
        const checkConsent = async () => {
            try {
                const isAuthenticated = await oktaAuth.isAuthenticated();
                if (isAuthenticated) {
                    const dataPrivacyAccepted = localStorage.getItem(constants.USER_CONSENT);
                    if (!dataPrivacyAccepted && window.location.pathname !== '/consent') {
                        window.location.href = `${window.location.origin}/consent`;
                    }
                }
            } catch (error) {
                console.error('Error checking consent:', error);
            }
        };

        checkConsent();
    }, [oktaAuth]);

    const restoreOriginalUri = async (_oktaAuth) => {
        const dataPrivacyAccepted = localStorage.getItem(constants.USER_CONSENT);
        const code = new URLSearchParams(window.location.search).get('code');

        // Handle consent first
        if (!dataPrivacyAccepted && (code || await _oktaAuth.isAuthenticated())) {
            window.location.href = `${window.location.origin}/consent`;
            return;
        }

        // After consent or if consent already exists
        if (dataPrivacyAccepted) {
            // Check for stored redirect
            const storedRedirect = localStorage.getItem('postLoginRedirect');
            if (storedRedirect) {
                // Clear it
                localStorage.removeItem('postLoginRedirect');
                // Use it
                window.location.href = `${window.location.origin}${storedRedirect}`;
                return;
            }
        }

        // Default fallback
        window.location.href = `${window.location.origin}/`;
    };

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <BrowserRouter>{children}</BrowserRouter>
        </Security>
    );
};

export default OktaProvider;
